import React from 'react';
import { faFileArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons';

import { AppWrapper, Button, FixedFooter, InputText, PageTitle, Pagination, ResizeTable } from 'components';
import { DefaultPropsType } from 'types';

import { path } from '../../../../utils';

import { BLACKLIST_DOMAINS } from './enums';
import { columns } from './contracts';
import { useFintelCheckBlacklist } from './hooks';
import BlacklistDomainModal from './Modal';
import * as Styled from './styles';

type FintelCheckBlacklistProps = DefaultPropsType;

const FintelCheckBlacklist = ({ permissionsCodeList = [] }: FintelCheckBlacklistProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useFintelCheckBlacklist(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderWrapperSyled>
        <PageTitle>{path.fintelCheckBlacklist.name}</PageTitle>

        <Styled.ButtonWrapperStyled>
          <Styled.ButtonStyled
            onClick={() => {
              hook.hookSetIsOpen();
              hook.hookSetModalInfo({ type: BLACKLIST_DOMAINS.MODAL.TYPES.ADD });
            }}
            disabled={isReadOnly}
          >
            {BLACKLIST_DOMAINS.BUTTONS.ADD}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            onClick={hook.hookDownload}
            theme="tertiary"
            loading={hook.hookCSVLoading}
            disabled={hook.hookCSVLoading}
          >
            <Styled.IconStyled icon={faFileArrowDown} />
            {BLACKLIST_DOMAINS.BUTTONS.DOWNLOAD}
          </Styled.ButtonStyled>
        </Styled.ButtonWrapperStyled>
      </Styled.HeaderWrapperSyled>

      <Styled.SearchWrapperStyled>
        <InputText
          type="url"
          placeholder={BLACKLIST_DOMAINS.PLACEHOLDERS.INPUT_TEXT}
          faIcon={faSearch}
          value={hook.hookSearch}
          onChange={hook.hookSetSearch}
        />

        <Styled.ButtonStyled onClick={hook.hookClearSearch} theme="text-only">
          {BLACKLIST_DOMAINS.BUTTONS.CLEAR}
        </Styled.ButtonStyled>
      </Styled.SearchWrapperStyled>

      <ResizeTable
        columns={columns(isReadOnly)}
        data={hook.hookList}
        onSort={hook.hookHandleSort}
        setOpenModal={hook.hookSetIsOpen}
        setModalInfo={hook.hookSetModalInfo}
        sortColumn={hook.hookSortColumn}
        onRowClick={hook.hookOnRowClick}
      />

      <Styled.PaginationWrapper>
        <Pagination
          currentPage={hook.hookCurrentPage}
          total={hook.hookTotalPages}
          onPageChange={hook.hookOnPageChange}
        />
      </Styled.PaginationWrapper>

      <FixedFooter>
        <Button
          onClick={hook.hookRemoveButton}
          theme="tertiary"
          disabled={hook.hookList.filter((item) => item.checked).length < 1 || isReadOnly}
        >
          {BLACKLIST_DOMAINS.BUTTONS.REMOVE}
        </Button>
      </FixedFooter>
      <BlacklistDomainModal hook={hook} />
    </AppWrapper>
  );
};
export default FintelCheckBlacklist;
