import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { TRANSACTION_CURRENCY } from '../../../../../utils';
import { useModal, useToast, useUserInfo } from '../../../../../hooks';
import { TRANSACTION_SETTINGS } from '../Tabs/TransactionSettings/enums';
import { copyToClipboard } from '../../../../../utils/copyToClipboard';
import { CREATE_KEY } from '../graphql/mutations';
import { GET_COMPANY } from '../graphql/queries';

export const useTransactionSettings = () => {
  const currencyList = TRANSACTION_CURRENCY;
  const userHook = useUserInfo();
  const { hookShowToast } = useToast();
  const [typeSettings, setTypeSettings] = useState<any[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>({ label: '', value: '' });
  const [currencyError, setCurrencyError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [autoDecline, setAutoDecline] = useState('');
  const [showAutoDeclineError, setShowAutoDeclineError] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [apiUrl, setApiUrl] = useState(TRANSACTION_SETTINGS.API_URL.PREFIX.replace(/graphql\/*/g, ''));
  const [allowNoOrderId, setAllowNoOrderId] = useState(false);
  const [allowPartnerId, setAllowPartnerId] = useState(false);
  const [allowPixeltracking, setAllowPixeltracking] = useState(false);
  const [allowPostback, setAllowPostback] = useState(false);
  const [autoApproveHeld, setAutoApproveHeld] = useState('');
  const [autoApprovePending, setAutoApprovePending] = useState('');
  const [tracked, setTracked] = useState('');
  const [deleteModal, setDeleteModal] = useModal(false);
  const [modalInfo, setModalInfo] = useState<{ type: string; url: string }>();
  const [deleteSetting, setDeleteSetting] = useState(false);

  const [createApiKey] = useMutation(CREATE_KEY);

  const currencyValidation = (value: SelectOption) => {
    if (value.value === '') {
      setCurrencyError(true);
    } else {
      setCurrencyError(false);
    }
  };

  const setCurrencyHandler = (value: SelectOption) => {
    setSelectedCurrency(value);
    currencyValidation(value);
  };

  const setModalOpenHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setAutoDeclineErrorHandler = (days: number) => {
    if (days > 180 || days < 0) {
      setShowAutoDeclineError(true);
    } else {
      setShowAutoDeclineError(false);
    }
  };

  const setAutoDeclineHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const autoDeclineDays = Math.floor(Number(e.target.value));
    setAutoDecline(autoDeclineDays.toString());
    setAutoDeclineErrorHandler(autoDeclineDays);
  };

  const blockInvalidNumber = (e: any) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: userHook.hookWhoAmI.companyId,
    },
    fetchPolicy: 'no-cache',
  });
  const getCompanyHandler = () => {
    const formattedTableData = () =>
      data.company.program.transactionSettings.integrations.map((item: any) => ({
        ...item,
        url: item.url || 'All URLs',
        delete: faTrash,
      }));

    if (data?.company) {
      setApiKey(data.company.apiAccessKey?.apiAccessKey);
      setApiUrl(
        data.company.apiAccessKey?.apiAccessKey
          ? `${TRANSACTION_SETTINGS.API_URL.PREFIX.replace(/graphql\/*/g, '')}${data.company.apiAccessKey?.apiAccessKey}`
          : ''
      );
      setAutoDecline(data.company.program.transactionSettings?.autoDeclineDays);
      setAllowNoOrderId(data.company.program.transactionSettings?.allowNoOrderId);
      setAllowPartnerId(data.company.program.transactionSettings?.allowPartnerId);
      setAllowPixeltracking(data.company.program.transactionSettings?.allowPixeltracking);
      setAllowPostback(data.company.program.transactionSettings?.allowPostback);
      setAutoApproveHeld(data.company.program.transactionSettings?.autoApproveHeld);
      setAutoApprovePending(data.company.program.transactionSettings?.autoApprovePending);
      setTracked(data.company.program.transactionSettings?.tracked);
      currencyList.map((currency) => {
        if (currency.value === data.company.program.transactionSettings?.currency) {
          setCurrencyHandler(currency);
        }
        return null;
      });
      setTypeSettings(formattedTableData());
    }
  };

  const generateApiKeyUrl = async () => {
    const { data: key, errors } = await createApiKey({
      variables: {
        input: {
          status: 'Active',
          companyId: userHook.hookWhoAmI.companyId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (errors) {
      hookShowToast('Error creating campaign');
      return;
    }

    if (key.createApiAccessKey) {
      setApiKey(key.createApiAccessKey.apiAccessKey);
      setApiUrl(`${TRANSACTION_SETTINGS.API_URL.PREFIX}${key.createApiAccessKey.apiAccessKey}`);
    }
  };

  const setModalInfoHandler = (row: any) => {
    setModalInfo({ type: row?.type, url: row?.url });
  };

  const copyValueHandler = (value: any) => {
    copyToClipboard(value);
  };

  useEffect(() => {
    getCompanyHandler();
  }, [data]);

  return {
    hookCurrencyOptions: currencyList,
    hookSelectedCurrency: selectedCurrency,
    hookSetCurrency: setCurrencyHandler,
    hookCurrencyError: currencyError,
    hookValidateCurrency: currencyValidation,
    hookSetModalOpen: setModalOpenHandler,
    hookIsModalOpen: isModalOpen,
    hookTransactionTypeSettingsData: typeSettings,
    hookSetTransactionsTypeSettingsData: setTypeSettings,
    hookLoading: false,
    hookDaysToAutoDecline: autoDecline,
    hookAutoDeclineError: showAutoDeclineError,
    hookBlockStrings: blockInvalidNumber,
    hookSetDaysToAutoDecline: setAutoDeclineHandler,
    hookApiKey: apiKey,
    hookApiUrl: apiUrl,
    hookGenerateApiKey: generateApiKeyUrl,
    hookCopyValue: copyValueHandler,

    hookIsDeleteModalOpen: deleteModal,
    hookSetDeleteModalOpen: setDeleteModal,
    hookSetModalInfo: setModalInfoHandler,
    hookModalInfo: modalInfo,
    hookDeleteSetting: deleteSetting,
    hookSetDeleteSetting: setDeleteSetting,

    hookAllowNoOrderId: allowNoOrderId,
    hookAllowPartnerId: allowPartnerId,
    hookAllowPixeltracking: allowPixeltracking,
    hookAllowPostback: allowPostback,
    hookAutoApproveHeld: autoApproveHeld,
    hookAutoApprovePending: autoApprovePending,
    hookTracked: tracked,
  };
};
