import React from 'react';

import { PageUrlCell } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/contracts/Component/PageUrlCell/index';
import { Checkbox } from 'components';
import {
  EditIcon,
  BlacklistIcon,
} from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/contracts/components';
import { TrackingUrlType } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';

import { CellStyled, CellWrapper } from '../styles';

export const columns = (
  isReadOnly: boolean,
  handleCheckBox: (checked: boolean, row: TrackingUrlType) => void,
  setBlacklistModalInfo: ({ id, trackingUrl, type, loadedCheckType }: Record<string, string | number>) => void,
  setBlacklistModaIsOpen: () => void
): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '4vw',
    formatter: (val, row) => (
      <CellStyled>
        <Checkbox key={val} checked={val} onChange={(checked) => handleCheckBox(checked, row)} disabled={isReadOnly} />
      </CellStyled>
    ),
  },
  {
    dataField: 'url',
    text: 'Url',
    width: '40vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <PageUrlCell val={val} />,
  },
  {
    dataField: 'status',
    text: 'Status',
    width: '10vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <CellWrapper>{val}</CellWrapper>,
  },
  {
    dataField: 'lastActive',
    text: 'Last Active',
    width: '10vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <CellWrapper>{val}</CellWrapper>,
  },
  {
    dataField: 'checkType',
    text: 'Check Type',
    width: '15vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <CellWrapper>{val}</CellWrapper>,
  },
  {
    dataField: 'edit',
    text: '',
    width: '4vw',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <EditIcon val={val} row={row} setOpenModal={setOpenModal} setModalInfo={setModalInfo} />
    ),
  },
  {
    dataField: 'blacklist',
    text: '',
    width: '4vw',
    notClickable: true,
    formatter: (val, row, _, __, ___) => (
      <BlacklistIcon val={val} row={row} setOpenModal={setBlacklistModaIsOpen} setModalInfo={setBlacklistModalInfo} />
    ),
  },
];
