import React from 'react';

import { Checkbox } from 'components';

import { Delete, Domain, Edit } from './components';
import { CellStyled } from './styles';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '3rem',
    formatter: (val) => (
      <CellStyled>
        <Checkbox key={val} checked={val} onChange={() => ''} disabled={isReadOnly} />
      </CellStyled>
    ),
  },
  {
    dataField: 'domain',
    text: 'Domain Name',
    width: '75vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <Domain val={val} />,
  },
  {
    dataField: 'edit',
    text: '',
    width: '50px',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Edit val={val} row={row} setOpenModal={setOpenModal} setModalInfo={setModalInfo} isReadOnly={isReadOnly} />
    ),
  },
  {
    dataField: 'delete',
    text: '',
    width: '50px',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Delete val={val} row={row} setOpenModal={setOpenModal} setModalInfo={setModalInfo} isReadOnly={isReadOnly} />
    ),
  },
];
