import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Pagination, ErrorBanner, ResizeTable, RecordsDropdown, Button, Select, Spacer } from 'components';
import { columns } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/contracts';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from 'utils';
import { URLS_LIST } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';
import { CHECK_TYPES_OPTIONS } from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/Tabs/UrlsList/enums';
import ConfirmationModal from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/ConfirmationModal';

import * as Styled from '../../styles';

import UrlModal from './Modal';
import { useTrackingUrls } from './hooks';

type FintelCheckUrlsListProps = {
  isReadOnly: boolean;
};

const FintelCheckTrackingUrlsList = ({ isReadOnly }: FintelCheckUrlsListProps): JSX.Element => {
  const hook = useTrackingUrls(isReadOnly);
  return (
    <>
      <Spacer direction="vertical" size="small" />
      <ErrorBanner message={hook.errorMessage} isOpen={!!hook.errorMessage} />
      <Styled.FiltersStyled>
        <Styled.InputTextStyled
          placeholder="Search by URL"
          type="text"
          value={hook.search}
          onChange={hook.setSearchHandler}
          faIcon={faSearch}
        />
        <Styled.ButtonStyled theme="text-only" onClick={hook.clearSearch}>
          {URLS_LIST.BUTTONS.CLEAR}
        </Styled.ButtonStyled>
      </Styled.FiltersStyled>
      <Styled.RecordsDropdownWrapper>
        <RecordsDropdown
          selected={hook.recordsPerPage}
          onChange={hook.setRecordsHandler}
          options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
          currentPage={hook.currentPage}
          totalRecords={hook.recordsCount}
        />
      </Styled.RecordsDropdownWrapper>
      <ResizeTable
        columns={columns(isReadOnly, hook.handleCheckBox, hook.setBlacklistModalInfo, hook.setBlacklistModaIsOpen)}
        data={hook.tableData}
        onSort={hook.handleSort}
        setOpenModal={hook.setIsOpen}
        setModalInfo={hook.setCheckTypeEditModalInfo}
        sortColumn={hook.sortColumn}
        isLoading={hook.getTrackingUrlsLoading}
      />
      {hook.tableData.length > 0 && (
        <Styled.PaginationWrapper>
          <Pagination currentPage={hook.currentPage} total={hook.totalPages} onPageChange={hook.onPageChange} />
        </Styled.PaginationWrapper>
      )}

      {hook.tableData.some((item) => item.checked) && (
        <Styled.DynamicFooterWrapper>
          <Styled.SubtitleStyled>Check Type</Styled.SubtitleStyled>
          <Styled.FlexRow>
            <Select
              name="checkType"
              placeholder="Select Check Type"
              selected={hook.selectedCheckType}
              onChange={hook.setSelectedCheckType}
              options={CHECK_TYPES_OPTIONS}
              isLoading={hook.updateTrackingUrlCheckTypeLoading}
              menuPlacement="top"
              width="40vw"
            />
            <Spacer direction="horizontal" size="medium" />
            <Button
              theme="primary"
              onClick={hook.handleUpdateUrls}
              disabled={
                hook.tableData.filter((item) => item.checked).length < 1 ||
                isReadOnly ||
                hook.updateTrackingUrlCheckTypeLoading ||
                !hook.selectedCheckType
              }
              width="15vw"
              loading={hook.updateTrackingUrlCheckTypeLoading}
            >
              {URLS_LIST.BUTTONS.UPDATE}
            </Button>
          </Styled.FlexRow>
        </Styled.DynamicFooterWrapper>
      )}
      <UrlModal hook={hook} />
      <ConfirmationModal hook={hook} />
    </>
  );
};

export default FintelCheckTrackingUrlsList;
