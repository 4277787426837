import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { TableProps } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/types';
import {
  CellCenterWrapper,
  IconButtonStyled,
} from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/styles';

export const BlacklistIcon = ({ val, row, setOpenModal, setModalInfo, isReadOnly }: TableProps): JSX.Element => (
  <CellCenterWrapper>
    <IconButtonStyled
      onClick={() => {
        setOpenModal();
        setModalInfo({
          id: row.id,
          trackingUrl: row.url,
          checked: row.checked,
          loadedCheckType: row.checkType,
        });
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={val} fontSize="1rem" />{' '}
    </IconButtonStyled>
  </CellCenterWrapper>
);
