import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from 'styles/theme';
import { Button } from 'components';

import { TableProps } from '../../types';
import { CellCenterWrapper } from '../../styles';
import { BLACKLIST_DOMAINS } from '../../../enums';

export const Edit = ({ val, row, setOpenModal, setModalInfo, isReadOnly }: TableProps): JSX.Element => (
  <CellCenterWrapper>
    <Button
      onClick={() => {
        setOpenModal();
        setModalInfo({
          id: row.id,
          domain: row.domain,
          checked: row.checked,
          type: BLACKLIST_DOMAINS.MODAL.TYPES.EDIT,
        });
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={val} color={colors.color1} />
    </Button>
  </CellCenterWrapper>
);
