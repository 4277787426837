import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../../styles/theme';
import { dateFormatter, PUBLISHER_PREFIX, path, USER_TYPES_NAMES } from '../../../../utils';
import { moneyFormater } from '../../PerfomanceReport/utils';
import { TMTableRowFlat, TMTotalsProcessed } from '../types';

const CellWrapperStyled = styled.div`
  color: ${colors.color1};
  height: 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CellLinkStyled = styled.a`
  color: ${colors.color1};
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: ${colors.color2};
  }
`;

const FooterWrapperStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color2};

  p {
    margin-bottom: 0.5rem;
  }
`;

const OpenAddButtonStyled = styled.button`
  background: transparent;
  border: none;
  font-size: 1rem;
  text-align: left;
  color: ${colors.color1};
  cursor: pointer;

  &:hover {
    color: ${colors.color2};
  }
`;

const defaultFooter = (
  <FooterWrapperStyled>
    <p>Total CA</p>
    <p>Total US</p>
  </FooterWrapperStyled>
);

const totalFooter = (cadUsd?: number[], isMoney = false) => (
  <FooterWrapperStyled>
    {cadUsd && (
      <>
        <p>
          {isMoney && `CAD ${moneyFormater.format(cadUsd[0])}`}
          {!isMoney && cadUsd[0]}
        </p>
        <p>
          {isMoney && `USD ${moneyFormater.format(cadUsd[1])}`}
          {!isMoney && cadUsd[1]}
        </p>
      </>
    )}
  </FooterWrapperStyled>
);

const callBack = (adCb: any, value: any) => (
  <CellWrapperStyled>
    {adCb && <OpenAddButtonStyled onClick={() => adCb(value)}>{value}</OpenAddButtonStyled>}
    {!adCb && { value }}
  </CellWrapperStyled>
);

export const transactionMasterColumns = (
  totals: TMTotalsProcessed | undefined,
  adCb?: (value: any) => void
): TableColumn[] => {
  const columnsValue: TableColumn[] = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footer: 'TOTAL',
      footerFormatter: () => defaultFooter,
      dataType: 'key',
      width: '150px',
    },
    {
      dataField: 'merchantId',
      text: 'Merchant Id',
      formatter: (value: any, row: TMTableRowFlat) =>
        row?.userType === USER_TYPES_NAMES.PUBLISHER ? (
          <CellWrapperStyled>
            <CellLinkStyled
              href={`${PUBLISHER_PREFIX}${path.programDetails.href}?programId=${row?.programId}`}
              target="_blank"
            >
              {value || 'N/A'}
            </CellLinkStyled>
          </CellWrapperStyled>
        ) : (
          <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>
        ),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'int',
      width: '150px',
    },
    {
      dataField: 'merchantName',
      text: 'Merchant Name',
      formatter: (value: any, row: TMTableRowFlat) =>
        row?.userType === USER_TYPES_NAMES.PUBLISHER ? (
          <CellWrapperStyled>
            <CellLinkStyled
              href={`${PUBLISHER_PREFIX}${path.programDetails.href}?programId=${row?.programId}`}
              target="_blank"
            >
              {value || 'N/A'}
            </CellLinkStyled>
          </CellWrapperStyled>
        ) : (
          <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>
        ),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'acid',
      text: 'Publisher CID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'publisherId',
      text: 'Publisher ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'int',
      width: '150px',
    },
    {
      dataField: 'publisherName',
      text: 'Publisher Name',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'orderId',
      text: 'Order ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'customerId',
      text: 'Customer ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'createdAt',
      text: 'Transaction Date',
      formatter: (value: any) => <CellWrapperStyled>{value ? dateFormatter(new Date(value)) : ''}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'processedDate',
      text: 'Processed Date',
      formatter: (value: any) => <CellWrapperStyled>{value ? dateFormatter(new Date(value)) : ''}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'transactionStatus',
      text: 'Transaction Status',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'productId',
      text: 'Product ID',
      formatter: (value: any) => <CellWrapperStyled>{value ?? 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'productName',
      text: 'Product Name',
      formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'adId',
      text: 'Ad ID',
      formatter: (value: any) => callBack(adCb, value),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'int',
      width: '150px',
    },
    {
      dataField: 'netSale',
      text: 'Net Sales',
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      footer: 'TOTAL',
      footerFormatter: () => totalFooter(totals?.totalNetSale, true),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'money',
      width: '150px',
    },
    {
      dataField: 'grossSale',
      text: 'Gross Sales',
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      footer: 'TOTAL',
      footerFormatter: () => totalFooter(totals?.totalGrossSale, true),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'money',
      width: '150px',
    },
    {
      dataField: 'commissionIds',
      text: 'Commision ID',
      formatter: (value: string[] | null) => <CellWrapperStyled>{value?.toString() || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'commissionAmount',
      text: 'Commission',
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      footer: 'TOTAL',
      footerFormatter: () => totalFooter(totals?.totalCommission, true),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'money',
      width: '150px',
    },
    {
      dataField: 'currency',
      text: 'Currency',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'commissionTypes',
      text: 'Commission Type',
      formatter: (value: any) => <CellWrapperStyled>{value?.toString() || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'referralUrl',
      text: 'Referral URL',
      formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'firstTransaction',
      text: 'New Transaction',
      formatter: (value: boolean | null) => <CellWrapperStyled>{value ? '' : 'False'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'processedBy',
      text: 'Processed By',
      formatter: (value: any, row: TMTableRowFlat) => (
        <CellWrapperStyled>
          {row.processedByFirstName ? `${row.processedByFirstName} ${row.processedByLastName}` : 'N/A'}
        </CellWrapperStyled>
      ),
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'note',
      text: 'Transaction Note',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'country',
      text: 'Transaction Country',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'trackingProfileUrl',
      text: 'Tracking Profile URL',
      formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'trackingProfileId',
      text: 'Tracking Profile ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'int',
      width: '150px',
    },
    {
      dataField: 'clickId',
      text: 'Click ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '100px',
    },
    {
      dataField: 'osBrowser',
      text: 'User OS/Browser',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'userAgent',
      text: 'User Agent',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'productCategory',
      text: 'Product Category',
      formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'thirdPartyUrl',
      text: 'Third Party URL',
      formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
    {
      dataField: 'systemUsageFee',
      text: 'System Usage Fee',
      formatter: (value: number | null) => <CellWrapperStyled>{value?.toFixed(2)}</CellWrapperStyled>,
      dataType: 'float',
      width: '150px',
    },
    {
      dataField: 'partnerId',
      text: 'Partner ID',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      dataType: 'string',
      width: '150px',
    },
  ];

  return columnsValue;
};
