import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useValidation } from 'utils/validation';
import { MANAGEMENT_FEES_CATEGORIES, PDF_TYPES, PRODUCT_CATEGORY, csvGenerator, path } from 'utils';

import {
  DEFAULT_TOTALS,
  DEPOSIT_TYPES,
  FEE_TYPES,
  MODAL_TYPES,
  PRODUCT_CATEGORIES,
  SORT_DEFAULT,
  validationfields,
} from '../contracts';
import { GET_ACCOUNT_BALANCE_CSV, MERCHANT_ACCOUNT_BALANCES, MERCHANT_PROGRAM } from '../graphql/query';
import { CREATE_FEE, DEPOSIT_FEE, UPDATE_PROGRAM_NEW_STATS } from '../graphql/mutation';
import { Permission } from '../../../../entities';

export const useAccountBalanceManagementStats = (permissionsCodeList: string[] = []) => {
  const location = useLocation();
  const navigate = useNavigate();

  const validtionHook = useValidation();

  const [getProgram] = useLazyQuery(MERCHANT_PROGRAM);
  const [getAccountBalances, { loading: getAccountBalancesLoading }] = useLazyQuery(MERCHANT_ACCOUNT_BALANCES);
  const [getAccountBalancesCSV] = useLazyQuery(GET_ACCOUNT_BALANCE_CSV);
  const [creatNewFee] = useMutation(CREATE_FEE);
  const [createNewDeposit] = useMutation(DEPOSIT_FEE);
  const [updateProgramStats] = useMutation(UPDATE_PROGRAM_NEW_STATS);

  const [merchantName, setMerchantName] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [programId, setProgramId] = useState('');

  const [isEnableNewStats, setIsEnableNewStats] = useState(true);
  const [enableNewStatsInput, setEnableNewStatsInput] = useState(false);
  const [enableNewStatsModalOpen, setEnableNewStatsModalOpen] = useState(false);
  const [updateStatsWarning, setUpdateStatsWarning] = useState(false);

  const [accountBalanceStatsState, setAccountBalanceStatsState] = useState<AccountBalanceStats[]>([]);
  const [monthyAccountBalance, setMonthyAccountBalance] = useState<MonthyAccountBalance[]>([]);
  const [monthyAccountBalanceTotals, setMonthyAccountBalanceTotals] = useState<{ [key: string]: any }>(DEFAULT_TOTALS);

  const [noRecords, setNoRecords] = useState<undefined | boolean>();

  const [selectedProductCategory, setSelectedProductCategory] = useState(PRODUCT_CATEGORIES[0]);

  const [date, setDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModelType] = useState(MODAL_TYPES.FEE);
  const [addModalCalenderOpen, setAddModalCalenderOpen] = useState(false);
  const [addModalAmount, setAddModalAmount] = useState('');
  const [addModalDate, setAddModalDate] = useState<Date | undefined>();
  const [selectedAddFeeType, setSelectedAddFeeType] = useState<SelectOption>(FEE_TYPES[0]);
  const [selectedAddDepositTypes, setSelectedAddDepositTypes] = useState<SelectOption>(DEPOSIT_TYPES[0]);
  const [addFeeTypeOther, setAddFeeTypeOther] = useState('');
  const [addModalReferenceNumber, setAddModalReferenceNumber] = useState('');
  const [addModalProductCategoryOptions, setAddModalProductCategoryOptions] = useState<SelectOption[]>([]);
  const [addSelectedModalProductCategory, setSelectedAddModalProductCategory] = useState<SelectOption | undefined>();
  const [addModalNote, setAddModalNote] = useState('');

  const [notifyMerchant, setNotifyMerchant] = useState(false);

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>(SORT_DEFAULT);

  const [isLoading, setIsLoading] = useState(true);
  const [addModalErrors, setAddModalErrors] = useState<{ [key: string]: string }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});

  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const formateStats = (stat: any, salesTaxRate: string) => {
    const newUseTieredRates: string[] = [];
    if (stat?.newUseTieredRate?.length > 0) {
      stat.newUseTieredRate.forEach((item: any, index: number) => {
        if (index === 0) {
          newUseTieredRates.push(
            `$0 to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`
          );
        }
        if (index > 0) {
          newUseTieredRates.push(
            `$${(Number(stat.newUseTieredRate[index - 1].newAdminTierCommission) + 0.01).toFixed(2)} 
            to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`
          );
        }
        if (stat.newUseTieredRate.length === index + 1) {
          newUseTieredRates.push(
            `Above $${Number(item.newAdminTierCommission).toFixed(2)}: 
            ${Number(item.newAdminAfterTierRate).toFixed(2)}%`
          );
        }
      });
    } else {
      newUseTieredRates.push(`${Number(stat.adminRate).toFixed(0)}%`);
    }
    return stat.activeCategories.map((activeCategory: string) => ({
      productCategory: activeCategory,
      useRateSettings: false,
      adminRate: stat.adminRate,
      minUsageFee: stat.minUsageFee,
      minBalanceRequired: stat.minBalanceRequired,
      salesTaxRate,
      managementFee: stat.managementFee[MANAGEMENT_FEES_CATEGORIES[activeCategory.toUpperCase()]],
      systemUserRate: newUseTieredRates,
    }));
  };

  const getAccountBalanceHandler = async (): Promise<void> => {
    const programIdState = programId || location.state?.programId;
    const {
      data: {
        programV2: { accountBalanceStats, accountStat, enableNewStats, salesTaxRate },
      },
    } = await getProgram({
      variables: {
        id: programIdState,
      },
      fetchPolicy: 'no-cache',
    });

    const companyProductCategory = accountStat?.activeCategories || [];

    const statData =
      accountStat && enableNewStats
        ? formateStats(accountStat, salesTaxRate)
        : [{ ...accountBalanceStats, salesTaxRate }];
    setAccountBalanceStatsState(statData);
    setAddModalProductCategoryOptions(companyProductCategory.map((obj: any) => ({ label: obj, value: obj })));
    setIsEnableNewStats(enableNewStats);
    setUpdateStatsWarning(
      accountBalanceStats?.accountStats ? !!accountBalanceStats?.accountStats[0].productCategory : false
    );
  };

  const getMonthlyBalance = async (): Promise<void> => {
    const input: { [key: string]: Date | string | boolean } = {
      merchantId: merchantId || location.state?.merchantId,
    };

    if (date) input.dateStart = date;
    if (endDate) input.dateEnd = endDate;
    if (selectedProductCategory.value !== '') input.productCategory = selectedProductCategory.value;

    const {
      data: {
        accountBalancesV2: { accountBalances, accountBalanceTotals, count },
      },
    } = await getAccountBalances({
      variables: {
        input: {
          ...input,
          options: {
            page: currentPage,
            items: 20,
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (noRecords === undefined) setNoRecords(count === 0);

    if (accountBalances) {
      setMonthyAccountBalance(accountBalances);
      setMonthyAccountBalanceTotals(accountBalanceTotals);
      setNumberOfPages(Math.ceil(count / 20));
    }
    setIsLoading(false);
  };

  const clearAddModalHandler = (): void => {
    setAddModalDate(undefined);
    setSelectedAddFeeType(FEE_TYPES[0]);
    setSelectedAddDepositTypes(DEPOSIT_TYPES[0]);
    setAddFeeTypeOther('');
    setAddModalReferenceNumber('');
    setSelectedAddModalProductCategory(undefined);
    setAddModalNote('');
    setAddModalErrors({});
    setAddModalAmount('');
  };

  const balanceReportingModalCancelHandler = (): void => {
    setEnableNewStatsInput(false);
    setEnableNewStatsModalOpen(false);
    setAddModalErrors({});
  };

  const balanceReportingModalUpdateHandler = async (): Promise<void> => {
    setAddModalErrors({});

    try {
      const input = {
        id: programId,
        enableNewStats: true,
      };
      const { errors } = await updateProgramStats({
        variables: {
          input,
        },
      });

      if (errors && errors.length > 0) throw errors[0];

      setIsEnableNewStats(true);
      setUpdateStatsWarning(true);
      setEnableNewStatsModalOpen(false);
    } catch (error: any) {
      setAddModalErrors({ message: error.message });
    }
  };

  const handleValidation = async (): Promise<boolean> => {
    const validationValues: { [key: string]: any } = {
      realNumberFee: addModalAmount,
      feeDate: addModalDate === undefined ? '' : addModalDate,
      feeType: selectedAddFeeType.value,
      referenceNumber: addModalReferenceNumber || undefined,
    };

    if (isEnableNewStats) {
      validationValues.productCategory =
        addSelectedModalProductCategory === undefined ? '' : addSelectedModalProductCategory.value;
    } else {
      validationValues.productCategory = 'placeholder';
    }

    if (modalType === MODAL_TYPES.DEPOSIT || selectedAddFeeType.value !== 'Other') {
      validationValues.feeTypeOther = 'placeholder';
    } else {
      validationValues.feeTypeOther = addFeeTypeOther;
    }
    return validtionHook.validateAll(validationValues, validationfields, setAddModalErrors, true);
  };

  const handleCalenderApply = (calDate: Date, calEndDate?: Date): void => {
    setDate(calDate);
    setEndDate(calEndDate);
    setCalendarOpen(false);
  };

  const csvDownloadHandler = async (): Promise<void> => {
    try {
      const input: { [key: string]: Date | string | boolean } = {
        merchantId: merchantId || location.state?.merchantId,
        enableNewStats: isEnableNewStats,
      };

      if (date) input.dateStart = date;
      if (endDate) input.dateEnd = endDate;
      if (selectedProductCategory.value !== '') input.productCategory = selectedProductCategory.value;

      if (selectedProductCategory.label !== PRODUCT_CATEGORY[0].label)
        input.productCategory = selectedProductCategory.label;
      const {
        data: { accountBalanceCSV },
        error,
      } = await getAccountBalancesCSV({
        variables: {
          input: {
            ...input,
            options: {
              order: sortColumn.direction?.toUpperCase(),
            },
            sortBy: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw new Error(error.message);

      if (accountBalanceCSV?.csv) {
        csvGenerator(accountBalanceCSV.csv, 'account-balance-report');
      }
    } catch (err: any) {
      setErrorMessages({ message: err.message });
    }
  };

  const createFeeHandler = async (): Promise<void> => {
    const input: { [key: string]: any } = {
      feeAmount: addModalAmount,
      feeDate: addModalDate === undefined ? '' : addModalDate,
      feeType: selectedAddFeeType.value,
      feeReferenceNumber: addModalReferenceNumber || undefined,
      feeNotes: addModalNote,
      currency: 'CAD',
      merchantId,
      other: addFeeTypeOther,
    };

    if (isEnableNewStats) {
      input.productCategory =
        addSelectedModalProductCategory === undefined ? '' : addSelectedModalProductCategory.value;
    }
    try {
      const { errors } = await creatNewFee({
        variables: {
          input,
        },
      });

      if (errors && errors.length > 0) throw errors[0];

      setAddModal(false);
      clearAddModalHandler();
      setRefetchTable(true);
    } catch (err: any) {
      setAddModalErrors({ message: err.message });
    }
  };

  const createNewDepositHandler = async (): Promise<void> => {
    try {
      const input: { [key: string]: any } = {
        depositAmount: addModalAmount,
        depositDate: addModalDate === undefined ? '' : addModalDate,
        depositType: selectedAddDepositTypes.value,
        depositReferenceNumber: addModalReferenceNumber || undefined,
        depositNotes: addModalNote,
        currency: 'CAD',
        merchantId,
        details: '',
        notifyMerchant,
      };

      if (isEnableNewStats) {
        input.productCategory =
          addSelectedModalProductCategory === undefined ? '' : addSelectedModalProductCategory.value;
      }

      const { errors } = await createNewDeposit({
        variables: {
          input,
        },
      });

      if (errors && errors.length > 0) throw errors[0];

      setAddModal(false);
      clearAddModalHandler();
    } catch (err: any) {
      setAddModalErrors({ message: err.message });
    }
  };

  const hookSaveHandler = async (): Promise<void> => {
    const valid = await handleValidation();
    if (!valid) return;

    if (modalType === 'deposit') {
      await createNewDepositHandler();
    }

    if (modalType === 'fee') {
      await createFeeHandler();
    }
    await getMonthlyBalance();
  };

  const newStatNavgateHandler = (): void => {
    navigate(path.merchantAccountBalanceManagementNewStats.href, {
      state: {
        merchantId,
        merchantName,
        programId,
      },
    });
  };

  const rowClickHandler = (row: any): void => {
    navigate(path.merchantAccountBalanceManagementDetails.href, {
      state: {
        merchantId: row.merchantId,
        merchant: row.merchant,
        accountBalancesId: row.id,
        productCategory: row.productCategory,
        finalized: row.finalized,
        month: Number(row.month),
        year: Number(row.year),
      },
    });
  };

  const navigatePdfHandler = (row: any): void => {
    navigate(path.invoiceStatement.href, {
      state: {
        merchantId: row.merchantId,
        accountBalancesId: row.id,
        productCategory: row.productCategory,
        pdfType: PDF_TYPES.MERCHANT,
        month: row.month?.toString(),
        year: row.year?.toString(),
      },
    });
  };

  const setSelectedProductCategoryHandler = (value: SelectOption): void => {
    setSelectedProductCategory(value);
  };

  const setDateHandler = (value: Date): void => {
    setDate(value);
  };

  const setEndDateHandler = (value: Date): void => {
    setEndDate(value);
  };

  const hookSetCalendarOpenHandler = (open: boolean): void => {
    setCalendarOpen(open);
  };

  const clearFormHandler = (): void => {
    setDate(undefined);
    setEndDate(undefined);
    setSelectedProductCategory(PRODUCT_CATEGORIES[0]);
  };

  const setAddModalDateHandler = (value: Date): void => {
    setAddModalDate(value);
    const errorsCopy = { ...addModalErrors };
    errorsCopy.feeDate = '';
    setAddModalErrors(errorsCopy);
    setAddModalCalenderOpen(false);
  };

  const setAddModalHandler = (value: boolean, type?: string): void => {
    setModelType(type || modalType);
    setAddModalDate(new Date());
    setAddModal(value);
    setAddModalDate(new Date());

    if (!value) {
      clearAddModalHandler();
    }
  };

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined): void => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setAddModalAmountHander = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorsCopy = { ...addModalErrors };
    errorsCopy.feeAmount = '';
    const value = event.target.value.replace(/[^0-9.-]/g, '');
    setAddModalErrors(errorsCopy);
    setAddModalAmount(value);
  };

  const setAddModalCalenderOpenHandler = (value: boolean): void => {
    setAddModalCalenderOpen(value);
  };

  const setSeletedAddFeeTypeHandler = (value: SelectOption): void => {
    const copy = addModalErrors;
    copy.feeTypeOther = '';
    setAddModalErrors(copy);
    setSelectedAddFeeType(value);
    setAddFeeTypeOther('');
  };

  const setSelectedAddDepositTypeHandler = (value: SelectOption): void => {
    setSelectedAddDepositTypes(value);
  };

  const setAddFeeTypeOtherHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorsCopy = { ...addModalErrors };
    errorsCopy.feeTypeOther = '';
    setAddModalErrors(errorsCopy);
    setAddFeeTypeOther(event.target.value);
  };

  const setAddModalReferenceNumberHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorsCopy = { ...addModalErrors };
    errorsCopy.referenceNumber = '';
    setAddModalErrors(errorsCopy);
    setAddModalReferenceNumber(event.target.value);
  };

  const setSelectedAddModalProductCategoryHandler = (value: SelectOption): void => {
    const errorsCopy = { ...addModalErrors };
    errorsCopy.productCategory = '';
    setAddModalErrors(errorsCopy);
    setSelectedAddModalProductCategory(value);
  };

  const setAddModalNoteHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAddModalNote(event.target.value);
  };

  const setNotifyMerchantHandler = (checked: boolean): void => {
    setNotifyMerchant(checked);
  };

  const setEnableNewStatsInputHandler = (checked: boolean): void => {
    setEnableNewStatsInput(checked);
    if (checked) setEnableNewStatsModalOpen(true);
  };

  const setCurrentPageHandler = (value: number): void => setCurrentPage(value);

  useEffect(() => {
    if (location.state) {
      setMerchantName(location.state?.merchantName || '');
      setMerchantId(location.state?.merchantId || '');
      setProgramId(location.state?.programId || '');
      getAccountBalanceHandler();
      getMonthlyBalance();
    }
  }, [location]);

  if (refetchTable) {
    setRefetchTable(false);
    getMonthlyBalance();
  }

  useEffect(() => {
    getMonthlyBalance();
  }, [endDate, date, selectedProductCategory, currentPage, JSON.stringify(sortColumn)]);

  return {
    hookMerchantName: merchantName,
    hookMerchantId: merchantId,
    hookProgramId: programId,

    hookAccountBalanceStats: accountBalanceStatsState,
    hookMonthyAccountBalance: monthyAccountBalance,
    hookMonthyAccountBalanceTotals: monthyAccountBalanceTotals,

    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: setSelectedProductCategoryHandler,

    hookDate: date,
    hookSetDate: setDateHandler,
    hookEndDate: endDate,
    hookSetEndDate: setEndDateHandler,

    hookHandleCalenderApply: handleCalenderApply,

    hookCalendarOpen: calendarOpen,
    hookSetCalendarOpen: hookSetCalendarOpenHandler,

    hookAddModal: addModal,
    hookSetAddModal: setAddModalHandler,
    hookAddModalType: modalType,
    hookSaveHandler,
    hookAddModalErrors: addModalErrors,

    hookAddModalCalenderOpen: addModalCalenderOpen,
    hookSetAddModalCalenderOpen: setAddModalCalenderOpenHandler,
    hookAddModalDate: addModalDate,
    hookSetAddModalDate: setAddModalDateHandler,

    hookAddAmount: addModalAmount,
    hookSetAddModalAmount: setAddModalAmountHander,

    hookSelectedAddFeeType: selectedAddFeeType,
    hookSetSelectedAddFeeType: setSeletedAddFeeTypeHandler,
    hookSelectedAddDepositTypes: selectedAddDepositTypes,
    hookSetSelectedAddDepositType: setSelectedAddDepositTypeHandler,

    hookAddFeeTypeOther: addFeeTypeOther,
    hookSetFeeTypeOther: setAddFeeTypeOtherHandler,

    hookAddModalReferenceNumber: addModalReferenceNumber,
    hookSetAddModalReferenceNumber: setAddModalReferenceNumberHandler,
    hookSelectedAddModalProductCategory: addSelectedModalProductCategory,
    hookSetSelectedAddModalProductCategory: setSelectedAddModalProductCategoryHandler,
    hookaddModalProductCategoryOptions: addModalProductCategoryOptions,

    hookSetAddModalNote: setAddModalNoteHandler,
    hookAddModalNote: addModalNote,

    hookNotifyMerchant: notifyMerchant,
    hookSetNotifyMerchant: setNotifyMerchantHandler,

    hookClearForm: clearFormHandler,
    hookCsvDownloadHandler: csvDownloadHandler,

    hookIsEnableNewStats: isEnableNewStats,

    hookEnableNewStatsInput: enableNewStatsInput,
    hookSetEnableNewStatsInput: setEnableNewStatsInputHandler,

    hookNumberOfPages: numberOfPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSortColumn: sortColumn,
    hookSetSortColumn: setSortColumnHandler,

    hookNoRecords: noRecords,

    hookNewStatNavgate: newStatNavgateHandler,
    hookRowClick: rowClickHandler,
    hookNavigatePdf: navigatePdfHandler,

    hookEnableNewStatsModalOpen: enableNewStatsModalOpen,
    hookBalanceReportingModalCancel: balanceReportingModalCancelHandler,
    hookBalanceReportingModalUpdate: balanceReportingModalUpdateHandler,
    hookUpdateStatsWarning: updateStatsWarning,

    hookIsLoading: isLoading,
    hookErrors: errorMessages,

    hookTableLoading: getAccountBalancesLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
