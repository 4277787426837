import { useEffect, useState } from 'react';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useModal, useUserInfo } from 'hooks';
import { useValidation } from 'utils/validation';
import { csvGenerator, paginator, sortString, URL_STATUSES } from 'utils';
import { useDebounce } from 'utils/useDebounce';

import { GET_BLACKLIST_DOMAIN_CSV, GET_FCHECK_SETTINGS } from '../graphql/queries';
import { BLACKLIST_DOMAINS } from '../enums';
import { UPDATE_FCHECK_SETTINGS } from '../graphql/mutations';
import { Permission } from '../../../../../entities';

export const useFintelCheckBlacklist = (permissionsCodeList: string[] = []) => {
  const navigate = useNavigate();
  const validator = useValidation();
  const { hookWhoAmI } = useUserInfo();

  // Table States
  const [tableData, setTableData] = useState<any[]>([]);
  const [domainsList, setDomainsList] = useState<any[]>([]);
  const [domainsTemp, setDomainsTemp] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: '', direction: 'asc' });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  // Modal States
  const [isOpen, setIsOpen] = useModal(false);
  const [modalType, setModalType] = useState(BLACKLIST_DOMAINS.MODAL.TYPES.ADD);
  const [domainUrl, setDomainUrl] = useState('');
  const [selectedDomains, setSelectedDomains] = useState<any[]>([]);
  const [domainStatus, setDomainStatus] = useState('');
  const [domainError, setDomainError] = useState('');
  const [ID, setID] = useState<number>(0);
  const [renderError, setRenderError] = useState(false);

  // Page States
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [getBlacklist, { loading: getBlacklistLoading }] = useLazyQuery(GET_FCHECK_SETTINGS);
  const [getBlacklistCSV, { loading: getCSVLoading }] = useLazyQuery(GET_BLACKLIST_DOMAIN_CSV);
  const [updateFintelCheckSettings] = useMutation(UPDATE_FCHECK_SETTINGS);

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const setDomainUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainUrl(e.target.value);
  };

  const validateUrl = () => {
    validator.validateUrlStatus(domainUrl, setDomainStatus, undefined, undefined, true);
  };

  const setModalInfo = ({ id, domain, type = BLACKLIST_DOMAINS.MODAL.TYPES.ADD }: Record<string, any>) => {
    setModalType(type);
    setID(id);
    setDomainUrl(domain);
  };

  const resetModal = () => {
    setID(0);
    setDomainUrl('');
    setSelectedDomains([]);
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowClick = (row: any) => {
    const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

    if (isReadOnly) return;

    const index = tableData.findIndex((item) => item.domain === row.domain);
    const tableDataCopy = [...tableData];
    tableDataCopy[index].checked = !tableDataCopy[index].checked;
    setTableData(tableDataCopy);
  };

  const invalidUrlOrDomain = () =>
    domainUrl === '' ||
    domainUrl === undefined ||
    domainStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
    domainStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
    domainStatus === URL_STATUSES.PENDING_WEBSITE.STATUS;

  // ===== Querying & Mutating Data in DB =====

  const handleGetData = async () => {
    const { data } = await getBlacklist({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getFintelCheckSettings?.settings?.blacklistDomains) {
      const formattedList = data.getFintelCheckSettings.settings.blacklistDomains.map(
        (item: string, index: number) => ({
          id: index,
          domain: item,
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        })
      );
      setDomainsList(formattedList);
      setTableData(paginator(formattedList, recordsPerPage, 1));
      setTotalPages(Math.ceil(data.getFintelCheckSettings.settings.blacklistDomains.length / recordsPerPage));
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
  };

  const handleGenerateCSV = async () => {
    const { data } = await getBlacklistCSV({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getBlacklistDomainCSV?.csv) {
      csvGenerator(data.getBlacklistDomainCSV.csv, 'Blacklist Domains List');
    }
  };

  const handleUpdateTableData = async (newTableData: any[]) => {
    const { data } = await updateFintelCheckSettings({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          blacklistDomains: newTableData.map((item) => item.domain),
        },
      },
    });
    if (data?.updateFintelSettingsFields?.settings?.blacklistDomains) {
      const formattedList = data.updateFintelSettingsFields.settings.blacklistDomains.map(
        (item: string, index: number) => ({
          id: index,
          domain: item,
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        })
      );
      setDomainsList(formattedList);
      setTableData(paginator(formattedList, recordsPerPage, 1));
      setTotalPages(Math.ceil(formattedList.length / recordsPerPage));
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
  };

  // ===== Table changes hanlders =====

  const handleSearch = () => {
    const domainsListToSet =
      debouncedSearch === ''
        ? domainsList
        : [...domainsList].filter((item) => item.domain.toLowerCase().includes(debouncedSearch));
    setDomainsTemp(domainsListToSet);
    setTableData(paginator(domainsListToSet, recordsPerPage, 1));
    setTotalPages(Math.ceil(domainsListToSet.length / recordsPerPage));
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    const paginatedData = paginator(debouncedSearch ? domainsTemp : domainsList, recordsPerPage, page);
    setTableData(paginatedData);
    setCurrentPage(page);
  };

  const handleSort = (dataField: string, direction: any) => {
    const directionString: string = direction === 'asc' ? 'asc' : 'desc';
    const copyArrayList = [...domainsList];
    const copyArray = debouncedSearch ? [...domainsList] : copyArrayList;
    const sortedArray = sortString(copyArray, dataField, directionString);
    const sortedArrayList = sortString(copyArrayList, dataField, directionString);
    setDomainsTemp(sortedArray);
    setDomainsList(sortedArrayList);
    setTableData(paginator(sortedArray, recordsPerPage, 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  // ===== Local changes (add, edit, remove) handlers =====

  const handleAddBlacklistDomain = () => {
    if (invalidUrlOrDomain()) {
      setRenderError(true);
      return;
    }
    if (!domainsList.map((item) => item.domain).includes(domainUrl)) {
      const newList = [
        ...domainsList,
        {
          id: domainsList.length,
          domain: domainUrl,
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        },
      ];
      handleUpdateTableData(newList);
    }
    setIsOpen();
    resetModal();
  };

  const handleEditBlacklistDomain = () => {
    if (invalidUrlOrDomain()) {
      setRenderError(true);
      return;
    }

    const domainToEditIndex = domainsList.findIndex((domain) => domain.id === ID);
    // Check if domain was edited (if not do nothing)
    if (domainToEditIndex !== -1 && domainsList[domainToEditIndex].domain !== domainUrl) {
      // Check if overlaps with any current Blacklist URL (if yes block edit)
      if (!domainsList.some((domain) => domain.domain === domainUrl && domain.id !== ID)) {
        const listCopy = [...domainsList];
        listCopy[domainToEditIndex].domain = domainUrl;
        handleUpdateTableData(listCopy);
      } else {
        setDomainError(BLACKLIST_DOMAINS.ERROR.DOMAIN_COLLISION);
        return;
      }
    }

    setIsOpen();
    resetModal();
  };

  const handleRemoveBlacklistDomain = () => {
    const listCopy = [...domainsList];
    const itemIndex = listCopy.findIndex((domain) => domain.id === ID);
    if (itemIndex !== -1) {
      listCopy.splice(itemIndex, 1);
      handleUpdateTableData(listCopy);
    }
    setIsOpen();
    resetModal();
  };

  const handleRemoveManyBlacklistDomains = () => {
    const listCopy = domainsList.filter((item) => !selectedDomains.includes(item.domain));
    handleUpdateTableData(listCopy);
    setIsOpen();
    resetModal();
  };

  // ===== Button handlers =====

  const handleRemoveButton = () => {
    setIsOpen();
    setModalType(BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY);
  };

  const handleModalRightButton = () => {
    switch (modalType) {
      case BLACKLIST_DOMAINS.MODAL.TYPES.ADD:
        validateUrl();
        handleAddBlacklistDomain();
        break;
      case BLACKLIST_DOMAINS.MODAL.TYPES.EDIT:
        validateUrl();
        handleEditBlacklistDomain();
        break;
      case BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE:
        handleRemoveBlacklistDomain();
        break;
      case BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY:
        handleRemoveManyBlacklistDomains();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    validator.renderUrlCheck(domainStatus, setDomainError);
    if (renderError) setRenderError(false);
  }, [domainStatus, renderError]);

  useEffect(() => {
    if (domainUrl === '' || domainUrl === undefined) setDomainError('');
    else if (
      modalType !== BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE &&
      modalType !== BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY
    )
      validateUrl();
  }, [domainUrl]);

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (modalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY)
      setSelectedDomains(tableData.filter((item) => item.checked === true).map((item) => item.domain));
  }, [modalType, isOpen]);

  useEffect(() => {
    handleGetData();
  }, []);

  return {
    hookLoading: getBlacklistLoading,
    hookCSVLoading: getCSVLoading,

    hookNavigate: navigate,
    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookClearSearch: clearSearch,

    hookList: tableData,
    hookSetList: setTableData,

    hookIsOpen: isOpen,
    hookSetIsOpen: setIsOpen,

    hookOnRowClick: handleRowClick,

    hookID: ID,
    hookDomain: domainUrl,
    hookSelectedDomains: selectedDomains,
    hookSetDomain: setDomainUrlHandler,
    hookDomainError: domainError,

    hookSetModalInfo: setModalInfo,
    hookModalType: modalType,
    hookResetModal: resetModal,

    hookSetPage: setCurrentPageHandler,
    hookOnPageChange: handlePageChange,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,

    hookHandleSort: handleSort,
    hookSortColumn: sortColumn,

    hookDownload: handleGenerateCSV,

    hookRemoveButton: handleRemoveButton,
    hookHandleModalRightButton: handleModalRightButton,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
