import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import React, { useLayoutEffect } from 'react';

import { Pagination, RecordsDropdown, ResizeTable, PageTitle, Spacer } from 'components';
import { SUMMARY } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/enums';
import { useMonitoringRulesReport } from 'pages/Merchants/FintelCheck/MonitoringRulesReport/hooks/useMonitoringRulesReport';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from 'utils';

import type { useHistoryReport } from './hooks/useHistoryReport';
import * as Styled from './styles';

type RulesHistoryReportProps = {
  historyHook: ReturnType<typeof useHistoryReport>;
  hook: ReturnType<typeof useMonitoringRulesReport>;
};

export const RulesHistoryReport = ({ historyHook, hook }: RulesHistoryReportProps): JSX.Element => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {historyHook.merchantName && <PageTitle>{historyHook.merchantName}</PageTitle>}

      <Spacer size="large" direction="vertical" />

      <Styled.OptionsRowStyled>
        <Styled.LeftStyled>
          <RecordsDropdown
            selected={historyHook.records}
            options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
            onChange={historyHook.setRecords}
            currentPage={historyHook.currentPage}
            totalRecords={historyHook.totalRecords}
          />
        </Styled.LeftStyled>

        <Styled.RightStyled>
          <Styled.ButtonStyled
            theme="secondary"
            onClick={() => {
              hook.setDisplaySummary(true);
              historyHook.resetPage();
            }}
            width="160px"
          >
            Back
          </Styled.ButtonStyled>
          <Styled.ButtonStyled theme="tertiary" onClick={() => historyHook.downloadHistoryReportCSV()} width="160px">
            <Styled.IconStyled icon={faFileDownload} />
            {SUMMARY.DOWNLOAD_CSV}
          </Styled.ButtonStyled>
        </Styled.RightStyled>
      </Styled.OptionsRowStyled>

      {historyHook.tableData && (
        <Styled.ReportWrapper>
          <ResizeTable
            key={historyHook.tableKey}
            data={historyHook.tableData}
            columns={historyHook.tableColumns}
            sortColumn={historyHook.sortColumn}
            onSort={historyHook.handleSort}
            onRowClick={(row) => hook.handlerSetDetailedViewModalInfo(row, historyHook.merchantId)}
            onDragEnd={historyHook.setOnDragEndHandler}
            isLoading={historyHook.isHistoryReportLoading}
          />
          <Pagination
            total={historyHook.totalPages}
            currentPage={historyHook.currentPage}
            onPageChange={historyHook.handlePageChange}
          />
        </Styled.ReportWrapper>
      )}
    </>
  );
};
