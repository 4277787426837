// eslint-disable-next-line import/no-extraneous-dependencies
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const URLS_LIST = {
  BUTTONS: {
    ADD: 'Add URL',
    DOWNLOAD: 'Download CSV',
    REMOVE: 'Remove URLs',
    CLEAR: 'Clear Form',
  },
  PLACEHOLDERS: {
    INPUT: 'https://www.fintelconnect.com',
  },
  MODAL: {
    TITLE: {
      ADD: 'Add URL',
      EDIT: 'Edit URL',
      REMOVE: 'Remove URL',
    },
    BUTTON: {
      ADD: 'Add',
      EDIT: 'Save',
      REMOVE: 'Remove',
      CANCEL: 'Cancel',
    },
    TYPES: {
      ADD: 'ADD',
      EDIT: 'EDIT',
      REMOVE: 'REMOVE',
      REMOVE_MANY: 'REMOVE_MANY',
    },
  },
  ERROR: {
    DOMAIN_COLLISION: 'URL already in Manual URL list',
    GET_DATA_ERROR: 'Could not load Manual Url list. Please try again later.',
    MISSING_CATEGORY: 'Please select a category',
    MISSING_CHECK_TYPE: 'Please select a check type',
    URL_TOO_LONG: 'URL must not exceed 2000 characters',
  },
};

export const URLS_CATEGORIES_OPTIONS = [
  { value: 'MANUAL', label: 'Manual' },
  { value: 'CONTENT_REVIEW', label: 'Content Review' },
];

export const URLS_CATEGORIES = {
  MANUAL: 'Manual',
  CONTENT_REVIEW: 'Content Review',
  TRACKING: 'Tracking',
};

export const CHECK_TYPES_OPTIONS = [
  { value: 'ALL_RULES', label: 'All Rules' },
  { value: 'MONITORING_RULES', label: 'Monitoring Rules' },
  { value: 'EVALUATION_RULES', label: 'Evaluation Rules' },
  { value: 'CHANGE_MONITORING_ONLY', label: 'Change Monitoring Only' },
];

export const CHECK_TYPES = {
  ALL_RULES: 'All Rules',
  MONITORING_RULES: 'Monitoring Rules',
  EVALUATION_RULES: 'Evaluation Rules',
  CHANGE_MONITORING_ONLY: 'Change Monitoring Only',
};

export type ManualUrlType = {
  id: number;
  url: string;
  checked: boolean;
  edit: IconDefinition;
  delete: IconDefinition;
  category: string;
  checkType: string;
};

export type TrackingUrlType = {
  id: number;
  url: string;
  checked: boolean;
  edit: IconDefinition;
  delete: IconDefinition;
  category: string;
  checkType: string;
};
